import React from "react";
import { Formik, Form as FForm, FormikConfig, useFormikContext } from "formik";
import { Client } from "../types";
import { ImageUpload, Input } from "components";
import { Link, useNavigate } from "react-router-dom";
import { RoutesEnum } from "Routes";
import { validationSchema, formDefaultValues, CLIENT_STATUS } from "../clientService";
import { useApi } from "hooks";
import { get, isEqual } from "lodash";

interface Props {
   initialValues?: Client;
}
export default function Form({ initialValues = {} }: Props) {
   const api = useApi({});
   const navigate = useNavigate();

   const formConfig: FormikConfig<Client> = {
      initialValues: { ...formDefaultValues, ...initialValues },
      validationSchema,
      onSubmit: async (values) => {
         const res = values.id ? await api.put(`/clients/${values.id}`, values) : await api.post("/clients", values);
         if (res) {
            navigate(`/${RoutesEnum.CLIENTS}`);
         }
      },
   };

   return (
      <>
         <Formik {...formConfig}>
            <TheForm initialValues={initialValues} />
         </Formik>
      </>
   );
}

function TheForm(props: any) {
   const { setFieldValue, isSubmitting, values, submitForm } = useFormikContext<Client>();

   const isBlocked = !!isEqual(get(props.initialValues, "status"), CLIENT_STATUS.BLOCKED);
   const isEditMode = !!get(values, "id");

   const block = () => {
      setFieldValue("status", CLIENT_STATUS.BLOCKED);
      submitForm();
   };

   const activate = () => {
      setFieldValue("status", CLIENT_STATUS.ACTIVE);
      submitForm();
   };

   return (
      <FForm>
         <div className="grid md:grid-cols-2 gap-3 md:gap-5">
            <div className="md:col-span-2">
               <div className="">
                  <ImageUpload name="logo" label="Logo" />
               </div>
            </div>
            <div>
               <Input name="name" label="Nom de l’entreprise" className="border-0 bg-grayLight/20 py-3" />
            </div>
            <div>
               <Input disabled={isEditMode} name="email" label="Adresse mail de connexion" className="border-0 bg-grayLight/20 py-3" />
            </div>
            <div>
               <Input name="historization_period" label="Période d’historisation (Jours)" type="number" className="border-0 bg-grayLight/20 py-3" />
            </div>
            <div>
               <Input name="mailjet_api_key" label="Clé API Mailjet" className="border-0 bg-grayLight/20 py-3" />
            </div>
            <div>
               <Input name="mailjet_secret_key" label="Clé secrète Mailjet" className="border-0 bg-grayLight/20 py-3" />
            </div>
         </div>
         <div className="mt-5 flex w-full gap-3 justify-end">
            <Link to={`/${RoutesEnum.CLIENTS}`}>
               <button className="basic outline-primary" type="button">
                  Annuler
               </button>
            </Link>
            {isEditMode && !isBlocked && (
               <button disabled={isSubmitting} onClick={block} type="button" className="basic  text-danger border border-danger">
                  Bloquer
               </button>
            )}
            {isEditMode && isBlocked && (
               <button disabled={isSubmitting} onClick={activate} type="button" className="basic  text-success border border-success">
                  Débloquer
               </button>
            )}
            <button disabled={isSubmitting} type="submit" className="basic primary">
               Enregistrer
            </button>
         </div>
      </FForm>
   );
}
