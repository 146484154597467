import { RoutesEnum } from "Routes";
import { ChartBarSquare, Gear, Home, Megaphone } from "assets/icons";
import logo from "assets/images/logo.png";
import { Footer, Img } from "components";
import { Logout } from "features/auth";
import { useCrud } from "hooks";
import { find, get } from "lodash";
import { NavLink, Outlet, useLocation } from "react-router-dom";

export default function ClientLayout() {
   const location = useLocation();
   const { data, isLoading } = useCrud({ url: `company-info` });
   const menuItems = [
      {
         label: "Acceuil",
         icon: <Home className="w-5" />,
         path: `/${RoutesEnum.MYSPACE}/${RoutesEnum.WELCOME}`,
      },
      {
         label: "Rapports",
         icon: <ChartBarSquare className="w-5" />,
         path: `/${RoutesEnum.MYSPACE}/${RoutesEnum.REPORTS}`,
      },
      {
         label: "Campagnes",
         icon: <Megaphone className="w-5" />,
         path: `/${RoutesEnum.MYSPACE}/${RoutesEnum.CAMPAIGNS}`,
      },
      {
         label: "Destinataires",
         icon: <ChartBarSquare className="w-5" />,
         path: `/${RoutesEnum.MYSPACE}/${RoutesEnum.RECIPIENTS}`,
      },
      {
         label: "Support",
         icon: <Gear className="w-5" />,
         path: `/${RoutesEnum.MYSPACE}/${RoutesEnum.SUPPORT}`,
      },
   ];

   const ActiveTab = () => {
      const activePathArrary = location.pathname.split("/");
      const activePath = "/" + [activePathArrary[1], activePathArrary[2]].join("/");
      const activeMenuItem = find(menuItems, ["path", activePath]);
      return (
         <>
            {activeMenuItem?.icon} <span>{activeMenuItem?.label}</span>
         </>
      );
   };

   return (
      <div className="">
         <div className="flex">
            <div className="border-r w-[18%] min-h-[93.9vh] hidden md:block sidebar">
               <div className="text-center py-10">
                  <img src={logo} className="h-8 mx-auto" alt="" />
               </div>
               <ul className="text-white container mx-auto  grid gap-2">
                  {menuItems.map((item) => (
                     <li key={item.label}>
                        <NavLink
                           to={item.path}
                           className={({ isActive, isPending }) =>
                              "flex gap-2 items-center  text-primary font-semibold text-lg py-2 px-3 inline-block " +
                              (isPending ? "pending" : isActive ? "bg-primary text-white [&_svg]:stroke-white" : "")
                           }
                        >
                           {item.icon}
                           {item.label}
                        </NavLink>
                     </li>
                  ))}
               </ul>
            </div>
            <div className="container  mx-auto px-3  min-h-[79dvh] md:min-h-[81dvh]">
               <div className="flex justify-between items-center gap-2 pt-4">
                  <p className="flex gap-3 text-primary text-2xl font-semibold">
                     <ActiveTab />
                  </p>
                  <div className="flex items-center gap-2">
                     <Img className="h-16 py-3 pr-5" src={isLoading ? "" : get(data, "data.logo")} />
                     <Logout />
                  </div>
               </div>
               <div className="mt-7">
                  <Outlet />
               </div>
            </div>
         </div>
         <Footer />
      </div>
   );
}
